import { FunctionComponent } from 'react';
import { predefinedRoutes } from './../../utils/constants';

import { useDispatch } from 'react-redux';
import {
  setStartLocation,
  setStartLocationCoordinates,
  setPredefinedRoute,
  setSelectedPredefinedRoute,
} from '../../redux/mainActions';
import {createStyles, makeStyles, Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 128
  },
  content: {
    maxWidth: '1725px',
    width: '100%',
  },
  title: {
    backgroundColor: '#7AB442',
    display: 'inline-block',
    padding: '16px 32px 16px 32px',
    fontFamiliy: 'Lato',
    fontSize: 50,
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    marginTop: 92,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: 'unset',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  subtitle: {
    font: 'italic normal normal 48px/57px Lato',
    letterSpacing: 0,
    color: '#000000',
    opacity: 1,
    marginTop: 42,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: 'unset',
    }
  },
  routes: {
    marginTop: 42,
  },
  route: {
    width: '320px',
    height: '320px',
    boxShadow: '10px 10px 10px #00000029',
    // border: '2px solid #000000',
    borderRadius: '15px',
    position: 'relative',
    backgroundSize: 'cover',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
      height: '160px',
    }
  },
  routeTitle: {
    font: 'italic normal bold 35px/42px Lato',
    letterSpacing: 0,
    color: '#FFFFFF',
    position: 'absolute',
    left: 8,
    bottom: 22,
    textShadow: '2px 2px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: 'unset',
      bottom: 18,
    }
  },
}))

const PredefinedRoutes: FunctionComponent = ({}) => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const renderRoutes = predefinedRoutes.map(({title, startLocation, startLocationCoordinates, routeLocations, image}, index) => {
      return (
        <Grid item xs={6} sm={6} md={4} spacing={0}>
        <div className={classes.route} style={{ backgroundImage: `url(images/locations/medium/${image})`}} onClick={() => setRoute(index, startLocation, startLocationCoordinates, routeLocations)}>
          <div className={classes.routeTitle}>
            {title}
          </div>
        </div>
        </Grid>
      );
    });

    const setRoute = (index: number, startLocation: string, startLocationCoordinates: any, routeLocations: number[]) => {
      dispatch(setStartLocation(startLocation));
      dispatch(setStartLocationCoordinates(startLocationCoordinates));
      dispatch(setPredefinedRoute(routeLocations));
      dispatch(setSelectedPredefinedRoute(index));
    }

    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.title}>
          TER PLAATSE
          </div>
          <div className={classes.subtitle}>
          Met een van onze favoriete routes of maak je eigen route
          </div>
          <Grid container spacing={3} className={classes.routes}>
            {renderRoutes}
          </Grid>
        </div>
      </div>
    );
}

export default PredefinedRoutes;
