import { createStore, applyMiddleware, } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import loggerMiddleWare from 'redux-logger';
import { mainReducer } from './mainReducer';

const configureStore = () => {
  return createStore(mainReducer, composeWithDevTools(
    applyMiddleware(loggerMiddleWare),
  ));
}

export default configureStore;
