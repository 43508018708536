import './App.css';
import React from 'react';

import Header from './components/header';
import LocationsCarousel from './components/locationsCarousel';
import PredefinedRoutes from './components/predefinedRoutes';
import GoogleMap from './components/googleMap';
import CustomerRoute from './components/customerRoute';
import PartnerCarousel from './components/partnerCarousel';
import {Container} from "@material-ui/core";

const styles = {
  background: {
    backgroundImage: `url(images/gc_background.png)`,
    position: 'absolute',
    top: 300,
    width: '100%',
    height: 2335,
    backgroundSize: 'cover',
    backgroundPosition: -1050,
    zIndex: -1,
  }

}
const App = () => (
  <>
    <div style={styles.background as React.CSSProperties}/>
    <Header />
    <LocationsCarousel />
    <Container maxWidth="lg" style={{overflow: 'hidden'}}>
      <PredefinedRoutes />
      <GoogleMap />
      <CustomerRoute />
    </Container>
    <PartnerCarousel />
  </>
);

export default App;
