interface Location {
    id: number;
    location: string;
    title: string;
    lat: number;
    lng: number;
    images: Array<string>;
    description: string;
}

interface Partner {
  id: number;
  name: string;
  image: string;
  url: string;
}

export const GOOGLE_API_KEY = 'AIzaSyApFsI_lWi7GnbgO_WQ4JAYqRWC7pBd3uE'; // TODO: do not use live

export const MAX_LOCATIONS_COUNT: number = 9;

export const INITIAL_MAP_CENTER  = {
  lat: 51.469373,
  lng: 5.345582,
}

export const predefinedRoutes = [
  {
    title: 'Rondje Eindhoven Noordwest',
    startLocation: 'Eindhoven, Netherlands',
    startLocationCoordinates: {lat: 51.462904095126035, lng: 5.427369017281367},
    routeLocations: [9, 8, 18, 41, 31, 39, 32, 35, 30],
    image: '0383.jpg',
    useRouteLatLng: null,
    gpxFile: null,
  },
  {
    title: 'Sporen van de Tweede Wereldoorlog',
    startLocation: 'Oirschot, Netherlands',
    startLocationCoordinates: {lat: 51.50293740533265, lng: 5.307194194311425},
    routeLocations: [58, 63, 64, 53, 46, 67, 47, 68, 69],
    image: '14.jpg',
    useRouteLatLng: [
        { lat: 51.5202389, lng: 5.2665806 },
        { lat: 51.5027444, lng: 5.265175 },
        { lat: 51.4750718, lng: 5.243614 },
        { lat: 51.4566507, lng: 5.2452777 },
        { lat: 51.4381333, lng: 5.2483417 },
        { lat: 51.4698757, lng: 5.2723025 },
        { lat: 51.475836,  lng: 5.283589 },
        { lat: 51.5017975, lng: 5.30712408 },
        { lat: 51.5029679, lng: 5.32035314 }
    ],
    gpxFile: 'Route Sporen van de Tweede Wereldoorlog.gpx',
  }
];

export const getLocations: Location[] = [
    {
        id: 0,
        location: 'Strijp-S Torenallee',
        title: 'Torenallee, Strijp-S',
        lat: 51.448040,
        lng: 5.453970,
        images: ['0001.jpg'],
        description: 'Strijp-S is een wijk en voormalig bedrijventerrein in het stadsdeel Strijp in Eindhoven. Het terrein behoorde toe aan elektronicaconcern Philips. Vanaf 2000 zijn er zowel woningen als bedrijven uit de creatieve sector gevestigd. De Torenallee is het fietspad dat je van de ene kant van Strijp-S naar de andere kant brengt.',
    },
    {
        id: 1,
        location: 'Philips Stadion',
        title: 'Philips Stadion',
        lat: 51.441962,
        lng: 5.469398,
        images: ['0007.jpg'],
        description: 'Het Philips Stadion is de thuisbasis van PSV. Het prachtige, moderne stadion bevindt zich in het centrum van Eindhoven en staat nog steeds op dezelfde plaats als bij de oprichting van de Philips Sport Vereniging in 1913.',
    },
    {
        id: 2,
        location: 'Strijp-S Torenallee',
        title: 'Torenallee, Strijp-S',
        lat: 51.446370,
        lng: 5.458650,
        images: ['0082.jpg', '0108.jpg'],
        description: 'Strijp-S is een wijk en voormalig bedrijventerrein in het stadsdeel Strijp in Eindhoven. Het terrein behoorde toe aan elektronicaconcern Philips. Vanaf 2000 zijn er zowel woningen als bedrijven uit de creatieve sector gevestigd. De Torenallee is het fietspad dat je van de ene kant van Strijp-S naar de andere kant brengt.',
    },
    {
        id: 3,
        location: 'Golfbaan Welschap',
        title: 'Golfbaan Welschap',
        lat: 51.448270,
        lng: 5.422660,
        images: ['0244.jpg', '0257.jpg', '0690.jpg'],
        description: 'Golfbaan Welschap',
    },
    {
        id: 4,
        location: 'Oirschotsedijk, ter hoogte van de Philips Fruittuin',
        title: 'Oirschotsedijk',
        lat: 51.462472,
        lng: 5.422222,
        images: ['0253.jpg', '0267.jpg', '07156.jpg', '07155.jpg'],
        description: 'De Oirschotsedijk is onderdeel van de Groene Corridor. De recreatieve stad en plattelandsverbinding tussen Eindhoven en Oirschot. Sinds 2013 is de Oirschotsedijk autovrij gemaakt en kan er nu heerlijk gefietst worden.',
    },
    {
        id: 5,
        location: 'Evoluon',
        title: 'Evoluon',
        lat: 51.4438694,
        lng: 5.4438592,
        images: ['0272.jpg'],
        description: 'Het Evoluon werd gebouwd als uithangbord voor de technologische vooruitgang en dan met name die aspecten daarvan waarbij het van oorsprong Eindhovense bedrijf Philips betrokken was. Het gebouw werd in 1966 ter gelegenheid van het 75-jarig bestaan van Philips aan de stad Eindhoven \'aangeboden\'',
    },
    {
        id: 6,
        location: 'Zwaanstraat, Tussen Strijp-T en Strijp-R',
        title: 'Zwaanstraat',
        lat: 51.450140,
        lng: 5.449511,
        images: ['0273.jpg', '6060.jpg'],
        description: 'De Zwaanstraat is onderdeel van de Groene Corridor. De recreatieve stad en plattelandsverbinding tussen Eindhoven en Oirschot. ',
    },
    {
        id: 7,
        location: 'Philips de Jongh Park',
        title: 'Philips de Jonghpark',
        lat: 51.4610595703125,
        lng: 5.426121234893799,
        images: ['0279.jpg', '7157.jpg', '8062.jpg', '8087.jpg', '8112.jpg'],
        description: 'Het historische Philips de Jongh wandelpark is één van de vele monumenten die het bedrijf Philips aan de stad achterliet. Het werd vernoemd naar de vrouw van Anton Philips, Anna Philips de Jongh, en in 1920 geschonken aan de stad Eindhoven. Het park vind je in het stadsdeel Strijp, in de buurt van Landgoed De Wielewaal, de plek waar de familie Philips zelf woonde.',
    },
    {
        id: 8,
        location: 'Brainport Industries Campus',
        title: 'Brainport Industries Campus',
        lat: 51.4611973,
        lng: 5.4028643,
        images: ['0346.jpg', '0379.jpg', '8719.jpg'],
        description: 'Hét gezicht van de hightech maakindustrie. Op Brainport Industries Campus komen de meest innovatieve en succesvolle bedrijven en instituten uit de Brainport regio bij elkaar als één geheel. De campus wordt dé plek waar innovatie- en concurrentiekracht van de hightech maakindustrie vleugels krijgt.',
    },
    {
        id: 9,
        location: 'Philips Fruittuin',
        title: 'Philips Fruittuin',
        lat: 51.4639833,
        lng: 5.4267895,
        images: ['0383.jpg', '4120.jpg'],
        description: 'In de jaren 30 van de 20e eeuw werd de Philips fruittuin aangelegd op grond waarvan de familie Philips eigenaar was, als werkverschaffingsproject. Zo konden een aantal werknemers van Philips ook tijdens de crisisjaren aan het werk blijven en het leverde ook fruit op als bijdrage aan de gezonde voeding van de Philipswerkers. Op dit moment kan je er een bezoekerscentrum, pannenkoekhuis, streekwinkel en speeltuin vinden. ',
    },
    {
        id: 10,
        location: 'Strijp-S Torenallee',
        title: 'Torenallee, Strijp-S',
        lat: 51.4470100402832,
        lng: 5.455065727233887,
        images: ['0453.jpg'],
        description: 'Strijp-S is een wijk en voormalig bedrijventerrein in het stadsdeel Strijp in Eindhoven. Het terrein behoorde toe aan elektronicaconcern Philips. Vanaf 2000 zijn er zowel woningen als bedrijven uit de creatieve sector gevestigd. De Torenallee is het fietspad dat je van de ene kant van Strijp-S naar de andere kant brengt.',
    },
    {
        id: 11,
        location: 'Tegenbosch fietsbrug',
        title: 'Fietsbrug, Tegenbosch',
        lat: 51.459028,
        lng: 5.411167,
        images: ['0623.jpg', '6997.jpg'],
        description: 'Tegenbosch is een 160 meter lange boogbrug die alle vier de rijbanen en de in- en uitvoegstroken overspant. De boog is 130 meter lang en de breedte van de brug varieert tussen de 5 en 7 meter. De brug ligt 7 meter boven de A2/N2 en het toppunt van de boog ligt 21 meter boven het brugdek.',
    },
    {
        id: 12,
        location: 'Strijp-T, gebouw TQ',
        title: 'Bedrijventerrein Strijp-T',
        lat: 51.4516094,
        lng: 5.4533111,
        images: ['0646.jpg'],
        description: 'Bedrijventerrein Strijp-T behoort, samen met Strijp-S en Strijp-R, tot de voormalige Philips productielocaties in stadsdeel Strijp. Inmiddels heeft Philips al zijn activiteiten op Strijp-T teruggetrokken Strijp-T wordt op dit moment gedomineerd door net naoorlogse gebouwen die zijn herontwikkeld. De energiecentrale die voorheen in de energiebehoefte van Philips voorzag, is in 2017 herontwikkeld tot het \'Innovation Powerhouse\'; een broedplaats voor vernieuwende bedrijven die passen binnen het \'"model Strijp-T\'',
    },
    {
        id: 13,
        location: 'Ingenieur Kalffstraat ',
        title: 'Ingenieur Kalffstraat , Strijp-S',
        lat: 51.4455732,
        lng: 5.4563978,
        images: ['3087.jpg', '3095.jpg'],
        description: 'Strijp-S is een wijk en voormalig bedrijventerrein in het stadsdeel Strijp in Eindhoven. Het terrein behoorde toe aan elektronicaconcern Philips. Vanaf 2000 zijn er zowel woningen als bedrijven uit de creatieve sector gevestigd. De Torenallee is het fietspad dat je van de ene kant van Strijp-S naar de andere kant brengt.',
    },
    {
        id: 14,
        location: 'Strijp-T, Van Berlo',
        title: 'Bedrijventerrein Strijp-T',
        lat: 51.453773498535156,
        lng: 5.447226047515869,
        images: ['0979.jpg'],
        description: 'Bedrijventerrein Strijp-T behoort, samen met Strijp-S en Strijp-R, tot de voormalige Philips productielocaties in stadsdeel Strijp. Inmiddels heeft Philips al zijn activiteiten op Strijp-T teruggetrokken Strijp-T wordt op dit moment gedomineerd door net naoorlogse gebouwen die zijn herontwikkeld. De energiecentrale die voorheen in de energiebehoefte van Philips voorzag, is in 2017 herontwikkeld tot het \'Innovation Powerhouse\'; een broedplaats voor vernieuwende bedrijven die passen binnen het \'model Strijp-T\'',
    },
    {
        id: 15,
        location: 'Zandpad tussen Anthony Fokkerweg en Philips Fruittuin',
        title: 'Route richting Philips Fruituin',
        lat: 51.465646,
        lng: 5.427435,
        images: ['3624.jpg', '3625.jpg'],
        description: 'In de jaren 30 van de 20e eeuw werd de Philips fruittuin aangelegd op grond waarvan de familie Philips eigenaar was, als werkverschaffingsproject. Zo konden een aantal werknemers van Philips ook tijdens de crisisjaren aan het werk blijven en het leverde ook fruit op als bijdrage aan de gezonde voeding van de Philipswerkers. Op dit moment kan je er een bezoekerscentrum, pannenkoekhuis, streekwinkel en speeltuin vinden. ',
    },
    {
        id: 16,
        location: 'Onderdoorgang bij het Flight Forum / Anthony Fokkerweg. Is de andere kant van de Vensedijk',
        title: 'Onderdoorgang bij Flight Forum / Anthony Fokkerweg',
        lat: 51.456806,
        lng: 5.402111,
        images: ['3706.jpg'],
        description: 'Onderdoorgang bij Flight Forum / Anthony Fokkerweg',
    },
    {
        id: 17,
        location: 'Vensedijk langs het kanaal',
        title: 'Fietspad de Vensedijk langs het kanaal',
        lat: 51.4540282,
        lng: 5.4133298,
        images: ['3736.jpg', '3728.jpg'],
        description: 'Fietspad de Vensedijk langs het kanaal',
    },
    {
        id: 18,
        location: 'Mispelhoef',
        title: 'Mispelhoef',
        lat: 51.467141,
        lng: 5.406861,
        images: ['Mispelhoef.jpg'],
        description: 'In de zijgevel van dit Rijksmonument staat het jaartal 1774. De geschiedenis van de Mispelhoeve gaat echter verder terug tot zelfs eind 16e eeuw. Tegenwoordig is er een restaurant gevestigd',
    },
    {
        id: 19,
        location: 'Bezuidenhoutseweg',
        title: 'Bezuidenhoutseweg, Philips de Jongh Park',
        lat: 51.4567275,
        lng: 5.44954925,
        images: ['3812.jpg'],
        description: 'In Nederland zijn vlak na de Tweede Wereldoorlog tijdens de wederopbouw zogenaamde Oostenrijkse woningen gebouwd. Dit waren geprefabriceerde woningen die in onderdelen vanuit Oostenrijk naar Nederland werden geleverd en door lokale aannemers werden opgebouwd.',
    },
    {
        id: 20,
        location: 'Torenallee, ter hoogte van Vershal \'"t Veem"',
        title: 'Torenallee, Strijp-S',
        lat: 51.4475875,
        lng: 5.4539505,
        images: ['6066.jpg'],
        description: 'Strijp-S is een wijk en voormalig bedrijventerrein in het stadsdeel Strijp in Eindhoven. Het terrein behoorde toe aan elektronicaconcern Philips. Vanaf 2000 zijn er zowel woningen als bedrijven uit de creatieve sector gevestigd. De Torenallee is het fietspad dat je van de ene kant van Strijp-S naar de andere kant brengt.',
    },
    {
        id: 21,
        location: 'Wandelpad tussen de Herdgang en Bio Art Laboratories ',
        title: 'Rabattenbos Eindhoven',
        lat: 51.4629425,
        lng: 5.4350975,
        images: ['08030.jpg'],
        description: 'Eind 2013 is als onderdeel van de Groene Corridor een 400 meter lang vlonderpad en -plateau van gerecycled kunststof aangelegd. Hierdoor is het Rabattenbos in Eindhoven weer bereikbaar voor wandelaars en mindervaliden.',
    },
    {
        id: 22,
        location: 'Authentiek Oirschot',
        title: 'Authentiek Oirschot',
        lat: 51.5034745,
        lng: 5.30851835,
        images: ['Authentiek_Oirschot_2.jpg'],
        description: 'Met de prachtig oude geveltjes',
    },
    {
        id: 23,
        location: 'Bourgondisch Oirschot ',
        title: 'Bourgondisch Oirschot ',
        lat: 51.50322465,
        lng: 5.30887735,
        images: ['Bourgongisch_Oirschot.jpg'],
        description: 'Een heerlijk plekje op het terras',
    },
    {
        id: 24,
        location: 'Dorpsgezicht Oirschot',
        title: 'Dorpsgezicht Oirschot',
        lat: 51.50292615,
        lng: 5.30690045,
        images: ['Dorpsgezicht_Oirschot.jpg'],
        description: 'Mooie winkels afgewisseld met horeca ',
    },
    {
        id: 25,
        location: 'Oude Raadhuis ',
        title: 'Oude Raadhuis ',
        lat: 51.50351255,
        lng: 5.30661255,
        images: ['Oude_Raadhuis.jpg'],
        description: 'Hier huist het toeristisch informatiepunt van Visit Oirschot',
    },
    {
        id: 26,
        location: 'Sfeervol Oirschot',
        title: 'Sfeervol Oirschot',
        lat: 51.50350435,
        lng: 5.3088145,
        images: ['Sfeervol_Oirschot.jpg'],
        description: 'Genieten op het terras ',
    },
    {
        id: 27,
        location: 'St. Petrus Basiliek ',
        title: 'St. Petrus Basiliek ',
        lat: 51.50354195,
        lng: 5.30712725,
        images: ['St_Petrus_Basiliek.jpg'],
        description: 'Indrukwekkende basiliek in hartje Oirschot',
    },
    {
        id: 28,
        location: 'Waterpomp, pompen maar… ',
        title: 'Waterpomp, pompen maar… ',
        lat: 51.50341365,
        lng: 5.30673075,
        images: ['Waterpomp.jpg'],
        description: 'Vul je waterfles op de Markt in Oirschot',
    },
    {
        id: 29,
        location: 'Loopgraaf voor de Vrede, landschapskunstwerk ',
        title: 'Loopgraaf voor de Vrede, landschapskunstwerk ',
        lat: 51.4876186765724245,
        lng: 5.3442210848436045,
        images: ['Loopgraaf_voor_de_Vrede.jpg'],
        description: 'Te vinden op militair oefenterrein van de Oirschotse heide',
    },
    {
        id: 30,
        location: 'Landgoed De Grote Beek',
        title: 'Landgoed De Grote Beek',
        lat: 51.468450,
        lng: 5.446660,
        images: ['Landgoed_de_Grote_Beek.jpg'],
        description: 'Landgoed De Grote Beek',
    },
    {
        id: 31,
        location: 'Eindhoven Airport',
        title: 'Eindhoven Airport',
        lat: 51.45886081017474,
        lng: 5.3918997315774035,
        images: ['Eindhoven_Airport1.jpg', 'Eindhoven_Airport2.jpg'],
        description: 'Eindhoven Airport',
    },
    {
        id: 32,
        location: 'Philips van Lenneppark',
        title: 'Philips van Lenneppark',
        lat: 51.44433478700038,
        lng: 5.431489277827869,
        images: ['Philips_van_Lenneppark.jpeg'],
        description: 'Philips van Lenneppark',
    },
    {
        id: 33,
        location: 'Overbruggen',
        title: 'Overbruggen',
        lat: 51.45831659057201,
        lng: 5.403040565578248,
        images: ['overbruggen.jpg'],
        description: 'Overbruggen',
    },
    {
        id: 34,
        location: 'Voormalig hoofdkantoor Philips',
        title: 'Voormalig hoofdkantoor Philips',
        lat: 551.466550,
        lng: 5.449860,
        images: ['Oud_hoofdkantoor_Philips.jpg'],
        description: 'Voormalig hoofdkantoor Philips',
    },
    {
        id: 35,
        location: 'Philips de Jonghpark',
        title: 'Philips de Jonghpark',
        lat: 51.455780309705695,
        lng: 5.4477714631872995,
        images: ['Philips_de_Jonghpark.jpg'],
        description: 'Philips de Jonghpark',
    },
    {
        id: 36,
        location: 'De Wielewaal',
        title: 'De Wielewaal',
        lat: 51.45341921617959,
        lng: 5.4445725765467605,
        images: ['Landgoed_de_Wielewaal.jpg'],
        description: 'De Wielewaal',
    },
    {
        id: 37,
        location: 'Urban Sportspark',
        title: 'Urban Sportspark',
        lat: 51.447740,
        lng: 5.437530,
        images: ['Urban_sportpark.jpg'],
        description: 'Urban Sportspark',
    },
    {
        id: 38,
        location: 'Echt Welschap',
        title: 'Echt Welschap',
        lat: 51.445750,
        lng: 5.412640,
        images: ['Foto_terminal_Welschap.jpg'],
        description: 'Echt Welschap',
    },
    {
        id: 39,
        location: 'Flight Forum',
        title: 'Flight Forum',
        lat: 51.4510092070474,
        lng: 5.401886950157581,
        images: ['Flight_Forum.jpg'],
        description: 'Flight Forum',
    },
    {
        id: 40,
        location: 'Spottersplek',
        title: 'Spottersplek',
        lat: 51.465114650052435,
        lng: 5.393098519634212,
        images: ['Spottersplek_door_spotter_Joris_van_Boven.jpg'],
        description: 'Spottersplek',
    },
    {
        id: 41,
        location: 'Groenblauwe knoop',
        title: 'Groenblauwe knoop',
        lat: 51.47128482028285,
        lng: 5.391707092757336,
        images: ['Groene_Corridor1.jpg', 'Groene_Corridor2.jpg'],
        description: 'Groenblauwe knoop',
    },
    {
        id: 42,
        location: 'Slowlane',
        title: 'Slowlane',
        lat: 51.45990476857427,
        lng: 5.400015121605046,
        images: ['Slowlane.jpg'],
        description: 'Slowlane',
    },
    {
        id: 43,
        location: 'Novotel',
        title: 'Novotel',
        lat: 51.458397,
        lng: 5.407467,
        images: ['Novotel.jpg'],
        description: 'Novotel',
    },
    {
        id: 44,
        location: 'Internationale School Eindhoven',
        title: 'Internationale School Eindhoven',
        lat: 51.44083,
        lng: 5.47778,
        images: ['Internationale_School_Eindhoven.jpg'],
        description: 'Internationale School Eindhoven',
    },
    // NEW - Oirschot spots
    {
        id: 45,
        location: 'Monumentje Coenraad van Valkenburg',
        title: 'Monumentje Coenraad van Valkenburg',
        lat: 51.4411972,
        lng: 5.213872222,
        images: ['1.jpg'],
        description: 'Kolonel Coenraad van Valkenburg van de Groningsche Vrijwillige Jagers sneuvelde in Boutersem tijdens de 10 daagse veldtocht in 1831 n.a.v. de Belgische opstand.',
    },
    {
        id: 46,
        location: 'Oefenhaven voor Duitse piloten WOII',
        title: 'Oefenhaven voor Duitse piloten WOII',
        lat: 51.4381333,
        lng: 5.248341667,
        images: ['2.jpg'],
        description: 'Zeeslagen op de Landschotse heide. In de 2e WO oefende Duitse piloten in opleiding hier met betonnen oefenbommen op nagebootste boten van zand.',
    },
    {
        id: 47,
        location: 'Schijnvliegveld met Duits vliegtuig WOII',
        title: 'Schijnvliegveld met Duits vliegtuig WOII',
        lat: 51.4793083,
        lng: 5.28253611,
        images: ['3.jpg'],
        description: 'In de 2e WO werd een nepvliegtuig over een rails gereden om Engelse bommenwerpers die op weg waren naar Duitsland te misleiden, zodat ze hun bommen hier lieten vallen.',
    },
    {
        id: 48,
        location: 'Monolieten Jo Gijsen',
        title: 'Monolieten Jo Gijsen',
        lat: 51.4635528,
        lng: 5.251202778,
        images: ['4.jpg'],
        description: 'Een van de vele kunstwerken van de kunstenaar Jo Gijsen die hier in de Beerze heeft gewoond en gewerkt. Een 50 tal kunstwerken zijn door de Heemkundekring “Den Beerschen Aard” teruggeplaatst',
    },
    {
        id: 49,
        location: 'Dorpspomp met waterpartij (ook luisterzuil) ',
        title: 'Dorpspomp met waterpartij (ook luisterzuil) ',
        lat: 51.467325,
        lng: 5.25026667,
        images: ['5.jpg'],
        description: 'Waterpartij met stroomgebied van de Grote- en Kleine Beerze die samen komen op landgoed Baest en als de Beerze verder stroomt langs de H. Eik.',
    },
    {
        id: 50,
        location: 'Landgoed Baest',
        title: 'Landgoed Baest',
        lat: 51.4903861,
        lng: 5.255130556,
        images: ['6.jpg'],
        description: 'Voormalig landgoed van 266 hectare van de Abdij van Tongerlo met zijn tiendhuis en tiendschuren. Landgoed vanaf 1818 particulier bezit en in 2016 Rijksmonument.',
    },
    {
        id: 51,
        location: 'Oude Kerkje',
        title: 'Oude Kerkje',
        lat: 51.4643167,
        lng: 5.2457722222,
        images: ['7.jpg'],
        description: 'Kerkje uit 1400 met toren 1450, buiten gebruik met de Vrede van Munster in 1648. Katholieken kregen hun kerk in 1796 terug. Bekend om zijn blikseminslag 13 april 1865 waarbij een vrouw een gave kreeg waardoor zij door handoplegging mensen kon genezen. (De Beerse Strijkster)',
    },
    {
        id: 52,
        location: 'Oliemolen Vlaest',
        title: 'Oliemolen Vlaest',
        lat: 51.4692028,
        lng: 5.245822222,
        images: ['8.jpg'],
        description: 'Replica van een rosmolen uit 1687. De stenen zijn in 2017 terug gevonden en hier geplaatst in 2018.',
    },
    {
        id: 53,
        location: 'Monument Jan Smulders',
        title: 'Monument Jan Smulders',
        lat: 51.4656556,
        lng: 5.249491667,
        images: ['9.jpg'],
        description: 'Burgemeester van Oost-, West- en Middelbeers moest van de Duitse be- zetter mannen aanwijzen voor de kustverdedigingswerken in Zeeland. Dit weigerde hij waardoor hij opgepakt werd en via Vught in Buchenwald gevangen gezet werd. Bij transport in april 1945 naar Flossenburg kwam hij te overlijden.',
    },
    {
        id: 54,
        location: 'Wonderboom',
        title: 'Wonderboom',
        lat: 51.4698167,
        lng: 5.244638889,
        images: ['10.jpg'],
        description: 'Uit een holle lindeboom uit 1800 groeide in de jaren 30 van de vorige eeuw een spar wat een attractie werd in Middelbeers.',
    },
    {
        id: 55,
        location: 'Toren (kapel) van de voormalige kerk te Oostelbeers',
        title: 'Toren (kapel) van de voormalige kerk te Oostelbeers',
        lat: 51.4712806,
        lng: 5.270433333,
        images: ['11.jpg'],
        description: 'Toren uit 1893 van de in 1967 gesloopte kerk met ernaast gelegen kerkhof met graven van piloten die in de 2e WO in de omgeving waren neergestort.',
    },
    {
        id: 56,
        location: 'Oude Toren',
        title: 'Oude Toren',
        lat: 51.4651556,
        lng: 5.26665,
        images: ['12.jpg'],
        description: 'Toren in het veld uit 1350, werd op 28 juli 1543 geplunderd door de soldaten van Maarten van Rossum, 1648 leegstand, kerk na instorting gesloopt.',
    },
    {
        id: 57,
        location: 'Boterkerkje',
        title: 'Boterkerkje',
        lat: 51.5028694,
        lng: 5.30858056,
        images: ['13.jpg'],
        description: 'Dit kerkje, gewijd aan Maria, is het oudste monument van Oirschot. Gebouwd rond 112 was het lange tijd de Kapittelkerk van Oirschot, tot de bouw  van de basiliek. Na 1648 is het gebruikt als opslagruimte en boterwaag, vandaar de bijnaam. De afgelopen 200 jaar is het een protestant kerkje geweest. Nu heeft het een multifunctionele invulling.',
    },
    {
        id: 58,
        location: 'Marktplein Oirschot',
        title: 'Marktplein Oirschot',
        lat: 51.50335,
        lng: 5.307169444,
        images: ['14.jpg'],
        description: 'Dit plein stond in de middeleeuwen vol huizen. Na een grote brand in 1623 werd de ruimte vóór de grote Petruskerk onbebouwd gelaten. Oirschot heeft nooit stadsrechten gehad, wel marktrechten en alle markten, inclusief de veemarkt, vonden vanaf die tijd plaats op dit hart van Oirschot.',
    },
    {
        id: 59,
        location: 'Groot Bijstervelt',
        title: 'Groot Bijstervelt',
        lat: 51.5080778,
        lng: 5.33391667,
        images: ['15.jpg'],
        description: 'Het middelste deel van dit complex is een kasteeltje, gebouw eind 18e eeuw door de toenmalige heer van Oirschot, Sweerts de Landas. Begin 20e eeuw werd dit woonhuis de basis van het klooster der Montfortanen, die hier tot begin 2000 verbleven. Het is nu een verzorgingstehuis.',
    },
    {
        id: 60,
        location: 'Stratense Kapel',
        title: 'Stratense Kapel',
        lat: 51.5117806,
        lng: 5.3416,
        images: ['16.jpg'],
        description: 'Deze kapel is gewijd aan de heilige Antonius. Hij werd aanbeden als heilige die de pest weerde. De middeleeuwse kapel werd in 1648, toen het protestantisme staatsgodsdienst werd, afgebroken en in 1853 opnieuw opgericht',
    },
    {
        id: 61,
        location: 'Landgoed Heerenbeek',
        title: 'Landgoed Heerenbeek',
        lat: 51.5425167,
        lng: 5.34499722,
        images: ['17.jpg'],
        description: 'In de 14e eeuw werd dit moerassige gebied (‘beek’) geschonken aan de Belgische Abdij van Park (de ‘heren’). Het landgoed heeft een hoeve die in de loop der eeuwen is uitgegroeid tot een landhuis.',
    },
    {
        id: 62,
        location: 'Monument vermoorde Italiaanse marskramertje',
        title: 'Monument vermoorde Italiaanse marskramertje',
        lat: 51.5414722,
        lng: 5.315722222,
        images: ['18.jpg'],
        description: 'In 1838 is op deze plek de 16-jarige Italiaanse marskramer Giovanni Castione vermoord vanwege de opbrengst van zijn prenten. In 2017 is dit monument onthuld in aanwezigheid van de nakomelingen van de familieleden van Castione.',
    },
    {
        id: 63,
        location: 'Watermolen Spoordonk',
        title: 'Watermolen Spoordonk',
        lat: 51.5202389,
        lng: 5.266580556,
        images: ['19.jpg'],
        description: 'Deze watermolen stamt uit 1320 en draait volledig op waterkracht van de Beerze. Het is de meest westelijke watermolen van Nederland. De molen is uitgevoerd met twee waterraderen: één rad werd gebruikt voor een oliemolen, met het andere wordt graan tot meel verwerkt.',
    },
    {
        id: 64,
        location: 'Heilige Eik',
        title: 'Heilige Eik',
        lat: 51.5027444,
        lng: 5.265175,
        images: ['20.jpg'],
        description: 'Deze kapel, opgedragen aan Maria, werd al in de middeleeuwen opgericht maar tijdens de reformatie afgebroken. In 1854 is de kapel herbouwd en trekt ook nu nog jaarlijks talloze bezoekers, die er graag een kaarsje opsteken.',
    },
    {
        id: 65,
        location: 's-Heerenvijvers',
        title: 's-Heerenvijvers',
        lat: 51.4896083,
        lng: 5.306730556,
        images: ['21.jpg'],
        description: 'Het landgoed is ontstaan in 1672, als een buitengoed van de Heer van Oirschot. Tegenwoordig is het een oase van rust en in bezit van de familie Eijsbouts.',
    },
    {
        id: 66,
        location: 'Stroomgebied de Beerze Logtsebaan',
        title: 'Stroomgebied de Beerze Logtsebaan',
        lat: 51.5448,
        lng: 5.2635361,
        images: ['22.jpg'],
        description: 'Dit waren vroeger beemden, grashooilanden, die in de natte seizoenen meestal onder water stonden. Vroeger werd dit gebied kort begraasd en gehooid. Het is nu een ideale schuilplaats voor diverse soorten dieren, zoals bijvoorbeeld reeën. De begroeiing die je hier ziet zijn veelal elzen, populieren en wilgen.',
    },
    {
        id: 67,
        location: 'Oorlogsgraven van omgekomen piloten',
        title: 'Oorlogsgraven van omgekomen piloten',
        lat: 51.4713056,
        lng: 5.27033333,
        images: ['Oorlogsgraven_van_omgekomen_piloten.png'],
        description: 'Oorlogsgraven van omgekomen piloten',
    },
    {
        id: 68,
        location: 'Veel schade aan klooster en woonhuizen',
        title: 'Veel schade aan klooster en woonhuizen',
        lat: 51.5015,
        lng: 5.30438889,
        images: ['Veel_schade_aan_klooster_en_woonhuizen.png'],
        description: 'Veel schade aan klooster en woonhuizen',
    },
    {
        id: 69,
        location: 'Basiliek Sint-Petrus',
        title: 'Basiliek Sint-Petrus',
        lat: 51.5032044,
        lng: 5.3065757,
        images: ['Basiliek_Sint-Petrus.png'],
        description: 'Basiliek Sint-Petrus',
    }
];

export const getPartners: Partner[] = [
  {
    id: 0,
    name: "Eindhoven noorwest",
    image: "eindhoven_noordwest.png",
    url: "https://www.eindhoven.nl/",
  },
  {
    id: 1,
    name: "Gemeente Oirschot",
    image: "oirschot.png",
    url: "https://www.oirschot.nl/",
  },
  {
    id: 2,
    name: "Brabants Landschap",
    image: "eindhoven.png",
    url: "https://www.brabantslandschap.nl/",
  },
  {
    id: 3,
    name: "Van Gogh Nationaal Park",
    image: "van_gogh_nationaal_park_brabant.png",
    url: "https://www.vangoghnationalpark.com/",
  },
  {
    id: 4,
    name: "Groene Corridor",
    image: "groenecorridor.png",
    url: "https://www.groenecorridor.nl",
  },
]
