import { Marker } from 'google-maps-react';

export interface MainState {
  activeLocationMarker: Marker | null,
  showingMarkerInfoWindow: boolean,
  selectedPlace: any,
  routeLocations: number[],
  startLocation: any,
  startLocationCoordinates: any,
  routeDistance: number | null,
  routeLegs: any[],
  selectedPredefinedRoute: number | null,
}

// markers
export const SET_ACTIVE_LOCATION_MARKER = 'markers/setActiveLocationMarker';
export const SET_SHOWING_MARKER_INFO = 'markers/setShowingMarkerInfo';
export const SET_SELECTED_PLACE = 'markers/setSelectedPlace';

// locations
export const ADD_ROUTE_LOCATION = 'routeLocations/add';
export const REMOVE_ROUTE_LOCATION = 'routeLocations/remove';
export const SET_ROUTE_LOCATIONS_ORDER = 'routeLocations/setOrder';
export const SET_START_LOCATION = 'routeLocations/setStartLocation';
export const SET_START_LOCATION_COORDINATES = 'routeLocations/setStartLocationCoordinates';

// route
export const SET_ROUTE_DISTANCE = 'route/setDistance';
export const SET_PREDEFINED_ROUTE = 'route/setPredefined';
export const REMOVE_ROUTE = 'route/remove';
export const SET_ROUTE_LEGS = 'route/setLegs';

// predefined route
export const SET_SELECTED_PREDEFINED_ROUTE = 'predefinedRoute/setSelected';

// markers
export interface SetActiveMarkerAction {
  type: typeof SET_ACTIVE_LOCATION_MARKER;
  payload: MainState['activeLocationMarker'];
}

export interface SetShowingMarkerInfoAction {
  type: typeof SET_SHOWING_MARKER_INFO;
  payload: MainState['showingMarkerInfoWindow'];
}

export interface SetSelectedPlaceAction {
  type: typeof SET_SELECTED_PLACE;
  payload: MainState['selectedPlace'];
}

// locations
export interface AddRouteLocationAction {
  type: typeof ADD_ROUTE_LOCATION;
  payload: number;
}

export interface RemoveRouteLocationAction {
  type: typeof REMOVE_ROUTE_LOCATION;
  payload: number;
}

export interface SetRouteLocationsOrderAction {
  type: typeof SET_ROUTE_LOCATIONS_ORDER;
  payload: number[];
}

export interface SetStartLocationAction {
  type: typeof SET_START_LOCATION;
  payload: any;
}

export interface SetStartLocationCoordinatesAction {
  type: typeof SET_START_LOCATION_COORDINATES;
  payload: any;
}

// route
export interface SetRouteDistanceAction {
  type: typeof SET_ROUTE_DISTANCE;
  payload: any;
}

export interface SetPredefinedRouteAction {
  type: typeof SET_PREDEFINED_ROUTE;
  payload: number[];
}

export interface RemoveRouteAction {
  type: typeof REMOVE_ROUTE;
}

export interface SetRouteLegsAction {
  type: typeof SET_ROUTE_LEGS;
  payload: any[]
}

// predefined route
export interface SetSelectedPredefinedRouteAction {
  type: typeof SET_SELECTED_PREDEFINED_ROUTE;
  payload: number | null;
}

export type MainActionTypes =
  | SetActiveMarkerAction
  | SetShowingMarkerInfoAction
  | SetSelectedPlaceAction
  | AddRouteLocationAction
  | RemoveRouteLocationAction
  | SetRouteLocationsOrderAction
  | SetStartLocationAction
  | SetStartLocationCoordinatesAction
  | SetRouteDistanceAction
  | SetPredefinedRouteAction
  | RemoveRouteAction
  | SetRouteLegsAction
  | SetSelectedPredefinedRouteAction
