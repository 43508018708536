import {
SET_ACTIVE_LOCATION_MARKER,
SetActiveMarkerAction,
SET_SHOWING_MARKER_INFO,
SetShowingMarkerInfoAction,
SET_SELECTED_PLACE,
SetSelectedPlaceAction,
ADD_ROUTE_LOCATION,
AddRouteLocationAction,
REMOVE_ROUTE_LOCATION,
RemoveRouteLocationAction,
SET_ROUTE_LOCATIONS_ORDER,
SetRouteLocationsOrderAction,
SET_START_LOCATION,
SetStartLocationAction,
SET_START_LOCATION_COORDINATES,
SetStartLocationCoordinatesAction,
SET_ROUTE_DISTANCE,
SetRouteDistanceAction,
SET_PREDEFINED_ROUTE,
SetPredefinedRouteAction,
REMOVE_ROUTE,
RemoveRouteAction,
SET_ROUTE_LEGS,
SetRouteLegsAction,
SET_SELECTED_PREDEFINED_ROUTE,
SetSelectedPredefinedRouteAction,
} from './types';

// markers
export const setActiveLocationMarker = (
  payload: SetActiveMarkerAction['payload'],
): SetActiveMarkerAction => ({
  type: SET_ACTIVE_LOCATION_MARKER,
  payload,
});

export const setShowingMarkerInfo = (
  payload: SetShowingMarkerInfoAction['payload'],
): SetShowingMarkerInfoAction => ({
  type: SET_SHOWING_MARKER_INFO,
  payload,
});

export const setSelectedPlace = (
  payload: SetSelectedPlaceAction['payload'],
): SetSelectedPlaceAction => ({
  type: SET_SELECTED_PLACE,
  payload,
});

// locations
export const addRouteLocation = (
  payload: AddRouteLocationAction['payload'],
): AddRouteLocationAction => ({
  type: ADD_ROUTE_LOCATION,
  payload,
});

export const removeRouteLocation = (
  payload: RemoveRouteLocationAction['payload'],
): RemoveRouteLocationAction => ({
  type: REMOVE_ROUTE_LOCATION,
  payload,
});

export const setRouteLocationsOrder = (
  payload: SetRouteLocationsOrderAction['payload'],
): SetRouteLocationsOrderAction => ({
  type: SET_ROUTE_LOCATIONS_ORDER,
  payload,
});

export const setStartLocation = (
  payload: SetStartLocationAction['payload'],
): SetStartLocationAction => ({
  type: SET_START_LOCATION,
  payload,
});

export const setStartLocationCoordinates = (
  payload: SetStartLocationCoordinatesAction['payload'],
): SetStartLocationCoordinatesAction => ({
  type: SET_START_LOCATION_COORDINATES,
  payload,
});

// route
export const setRouteDistance = (
  payload: SetRouteDistanceAction['payload'],
): SetRouteDistanceAction => ({
  type: SET_ROUTE_DISTANCE,
  payload,
});

export const setPredefinedRoute = (
  payload: SetPredefinedRouteAction['payload'],
): SetPredefinedRouteAction => ({
  type: SET_PREDEFINED_ROUTE,
  payload,
});

export const removedRoute = (
): RemoveRouteAction => ({
  type: REMOVE_ROUTE,
});

export const setRouteLegs = (
  payload: SetRouteLegsAction['payload'],
): SetRouteLegsAction => ({
  type: SET_ROUTE_LEGS,
  payload,
});

// predefined route
export const setSelectedPredefinedRoute = (
  payload: SetSelectedPredefinedRouteAction['payload'],
): SetSelectedPredefinedRouteAction => ({
  type: SET_SELECTED_PREDEFINED_ROUTE,
  payload,
});