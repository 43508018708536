import { MainState, MainActionTypes, SET_ACTIVE_LOCATION_MARKER, SET_SHOWING_MARKER_INFO, SET_SELECTED_PLACE, ADD_ROUTE_LOCATION, REMOVE_ROUTE_LOCATION, SET_ROUTE_LOCATIONS_ORDER,
  SET_START_LOCATION, SET_START_LOCATION_COORDINATES, SET_ROUTE_DISTANCE, SET_PREDEFINED_ROUTE, REMOVE_ROUTE, SET_ROUTE_LEGS, SET_SELECTED_PREDEFINED_ROUTE } from './types'

// const initialState: MainState = {
//   activeLocationMarker: null,
//   showingMarkerInfoWindow: false,
//   selectedPlace: null,
//   routeLocations: [1, 5, 14, 20],
//   startLocation: null,
//   startLocationCoordinates: {lat: 51.441643, lng: 5.469722},
//   routeDistance: null,
// };


const initialState: MainState = {
  activeLocationMarker: null,
  showingMarkerInfoWindow: false,
  selectedPlace: null,
  routeLocations: [],
  startLocation: null,
  startLocationCoordinates: null,
  routeDistance: null,
  routeLegs: [],
  selectedPredefinedRoute: null,
};

export function mainReducer(
  state = initialState,
  action: MainActionTypes,
): MainState {
  switch (action?.type) {
    case SET_ACTIVE_LOCATION_MARKER:
      return {
        ...state,
        activeLocationMarker: action.payload,
      };
    case SET_SHOWING_MARKER_INFO:
      return {
        ...state,
        showingMarkerInfoWindow: action.payload,
      };
    case SET_SELECTED_PLACE:
      return {
        ...state,
        selectedPlace: action.payload,
      };
    case ADD_ROUTE_LOCATION:
      return {
        ...state,
        routeLocations: state.routeLocations.concat(action.payload),
      };
    case REMOVE_ROUTE_LOCATION:
      return {
        ...state,
        routeLocations: state.routeLocations.filter(id => id !== action.payload)
      }
    case SET_ROUTE_LOCATIONS_ORDER:
       return {
         ...state,
         routeLocations: state.routeLocations = action.payload
       }
    case SET_START_LOCATION:
      return {
        ...state,
        startLocation: action.payload,
      }
   case SET_START_LOCATION_COORDINATES:
       return {
         ...state,
         startLocationCoordinates: action.payload,
       }
    case SET_ROUTE_DISTANCE:
        return {
          ...state,
          routeDistance: action.payload,
        }
    case SET_PREDEFINED_ROUTE:
        return {
          ...state,
          routeLocations: action.payload,
        }
    case REMOVE_ROUTE:
      return {
        ...state,
        activeLocationMarker: null,
        showingMarkerInfoWindow: false,
        selectedPlace: null,
        routeLocations: [],
        routeDistance: null,
      }
    case SET_ROUTE_LEGS:
      return {
        ...state,
        routeLegs: action.payload,
      }
    case SET_SELECTED_PREDEFINED_ROUTE:
      return {
        ...state,
        selectedPredefinedRoute: action.payload,
      }
    default:
      return state;
  }
}
