import React, { FunctionComponent, useRef } from 'react';
import { useSelector } from 'react-redux';
import { downloadGpxFile, createGoogleMapsUrl, createGoogleMapsUrlWithLatLng } from './../../utils/routeHelper';
import {
  getRouteDistance,
  getRouteLocations,
  getStartLocationCoordinates,
  getRouteLegs,
  getSelectedPredefinedRoute,
} from '../../redux/mainSelectors';
import {createStyles, makeStyles, Grid} from "@material-ui/core";

import { useReactToPrint } from 'react-to-print';
import { RouteToPrint } from './../routeToPrint';
import {predefinedRoutes} from './../../utils/constants';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  content: {
    height: 798,
    background: '#F4F4F4 0% 0% no-repeat padding-box',
    borderRadius: '0px 0px 20px 20px',
    boxShadow: '10px 10px 10px #00000029',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      height: 560,
    },
  },
  title: {
    font: 'normal normal bold 88px/108px Helvetica Neue',
    letterSpacing: 0,
    color: '#000000',
    paddingTop: 100,
    marginLeft: 121,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 'unset',
      marginLeft: 24,
    },
  },
  subtitle: {
    font: 'normal normal normal 28px/34px Helvetica Neue',
    letterSpacing: 0,
    color: '#000000',
    marginLeft: 121,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: 'unset',
      marginLeft: 24,
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 121,
    marginTop: 65,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 24,
    },
  },
  button: {
    backgroundColor: '#A3EF82',
    borderRadius: 4,
    borderStyle: 'hidden',
    color: 'white',
    width: '258px',
    height: '45px',
    textDecoration: 'none',
    display: 'inline-block',
    font: 'normal normal bold 21px/26px Helvetica Neue',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: 'unset',
      width: '200px',
      height: '45px',
      marginBottom: 16,
    },
  },
  iconButton: {
    width: 64,
    height: 64,
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      width: 48,
      height: 48,
    },
  }
}))

const CustomerRoute: FunctionComponent = ({}) => {
  const classes = useStyles();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const routeDistance = useSelector(getRouteDistance);
  const startLocationCoordinates = useSelector(getStartLocationCoordinates);
  const routeLocations = useSelector(getRouteLocations);
  const routeLegs = useSelector(getRouteLegs);
  const selectedPredefinedRoute = useSelector(getSelectedPredefinedRoute);

  const renderRouteDistance = () => {
    if (routeDistance) {
      return (<div className={classes.title} style={{opacity: routeDistance ? 1 : 0.3}}>Jouw route is {routeDistance} km</div>);
    } else {
      return (<div className={classes.title} style={{opacity: routeDistance ? 1 : 0.3}}>Jouw route is ..</div>);
    }
  }

  const downloadGpx = () => {
    if (selectedPredefinedRoute && predefinedRoutes[selectedPredefinedRoute].gpxFile != null) {
      window.open(`gpx/${predefinedRoutes[selectedPredefinedRoute].gpxFile}`);
    } else {
      downloadGpxFile(routeLegs, routeDistance);
    }
  }

  const openGoogleMaps = () => {
    if (selectedPredefinedRoute && predefinedRoutes[selectedPredefinedRoute].useRouteLatLng != null) {
      window.open(createGoogleMapsUrlWithLatLng(predefinedRoutes[selectedPredefinedRoute].useRouteLatLng, startLocationCoordinates), '_blank');
    } else {
      window.open(createGoogleMapsUrl(routeLocations, startLocationCoordinates), '_blank');
    }
  }

    return (
      <div className={classes.container}>
      <Grid xs={12} spacing={0}>
        <div className={classes.content}>
          {renderRouteDistance()}
          <div className={classes.subtitle} style={{opacity: routeDistance ? 1 : 0.3}}>
            <p>Toffe route! Je kan deze route hier downloaden, doorsturen naar Google Maps of printen om te navigeren.</p> <p>Veel plezier!</p>
          </div>
          <div className={classes.buttonContainer} style={{opacity: routeDistance ? 1 : 0.3}}>
            <button onClick={() => downloadGpx()} className={classes.button} disabled={routeDistance === null} >DOWNLOAD GPX</button>
            <input aria-label={'Open Google Maps'} onClick={() => openGoogleMaps()} className={classes.iconButton} type="image" src="images/icons/google_maps_icon.png" disabled={routeDistance === null} />
            
            <input aria-label={'Print route'} onClick={handlePrint} className={classes.iconButton} type="image" src="images/icons/print_icon.png" disabled={routeDistance === null}  />
          </div>
        </div>
        </Grid>
        <div style={{display: 'none'}} >
          <RouteToPrint ref={componentRef} /> 
        </div>
      </div>
    );
}

export default CustomerRoute;


