import React, { ComponentProps, } from 'react';
import { useSelector } from 'react-redux';
import {
  getRouteLocations,
  getStartocation,
} from '../../redux/mainSelectors';
import { getLocationById } from './../../utils/locationHelper';
import {makeStyles, createStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => createStyles({
  container: {
    padding: 64,
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
  },
  locationImage: {
    borderRadius: 10,
    width: 100,
    height: 100,
    backgroundSize: 'cover',
  },
  infoContainer: {
    marginLeft: 16,
  },
  title: {
    font: 'normal normal bold 16px/19px Helvetica Neue',
    letterSpacing: 0,
    color: '#000000',
  },
  description: {
    overflow: 'hidden',
    font: 'normal normal normal 16px/18px Helvetica Neue',
    letterSpacing: 0,
    color: '#000000',
  },
  pin: {
    width: 46,
    height: 46,
  },
  startDestinationInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -2,
    marginLeft: 16,
  },
}))

export const RouteToPrint = React.forwardRef<HTMLDivElement, ComponentProps<any>>((props, ref) => {
  const classes = useStyles();
  const routeLocations = useSelector(getRouteLocations);
  const startLocation = useSelector(getStartocation);

  var location: string = 'Location';

  if (typeof startLocation === 'object' && startLocation !== null) {
    location = startLocation.label;
  } else {
    location = startLocation;
  }

  const usePageBreak: boolean = routeLocations.length > 7 ? true : false;
  console.log(usePageBreak);
  return (<div ref={ref} className={classes.container}>
      <div className={classes.location}> 
                        <div className={classes.imageContainer}>
                        <img src={"./images/icons/pin.png"} className={classes.pin} />
                        </div>
                        <div className={classes.startDestinationInfoContainer}>
                          <div className={classes.title}>
                            {location}
                          </div>
                        </div>
                  </div>

      {routeLocations.map((item, index) => (
                  <div className={classes.location} style={{ pageBreakBefore: (index == 6 && usePageBreak == true) ? 'always' : 'auto', paddingTop: (index == 6 && usePageBreak == true) ? 64 : 0 }} > 
                        <div className={classes.imageContainer} >
                          <div className={classes.locationImage} style={{backgroundImage: `url(images/locations/medium/${getLocationById(item).images[0]})`}} />
                        </div>
                        <div className={classes.infoContainer}>
                          <div className={classes.title}>
                            {getLocationById(item).title}
                          </div>
                          <div className={classes.description}>
                            {getLocationById(item).description}
                          </div>
                        </div>
                  </div>
        
                ))}


<div className={classes.location}> 
                        <div className={classes.imageContainer}>
                        <img src={"./images/icons/pin.png"} className={classes.pin} />
                        </div>
                        <div className={classes.startDestinationInfoContainer}>
                          <div className={classes.title}>
                          {location}
                          </div>
                        </div>
                  </div>
      </div>
  )
});