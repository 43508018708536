import React, {FunctionComponent, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {GoogleApiWrapper, IMarkerProps, InfoWindow, Map, Marker} from 'google-maps-react';
import {getLocations, GOOGLE_API_KEY, INITIAL_MAP_CENTER, MAX_LOCATIONS_COUNT, predefinedRoutes} from './../../utils/constants';
import {getLocationById} from './../../utils/locationHelper';
import RouteLocations from './../routeLocations';
import {useDispatch, useSelector} from 'react-redux';
import {
  addRouteLocation,
  removedRoute,
  removeRouteLocation,
  setActiveLocationMarker,
  setRouteDistance,
  setSelectedPlace,
  setShowingMarkerInfo,
  setRouteLegs,
  setSelectedPredefinedRoute,
} from '../../redux/mainActions';
import {
  getActiveLocationMarker,
  getRouteLocations,
  getSelectedPlace,
  getShowingMarkerInfoWindow,
  getStartLocationCoordinates,
  getStartocation,
  getSelectedPredefinedRoute,
} from '../../redux/mainSelectors';
import {makeStyles, createStyles, Grid} from "@material-ui/core";

const styles = {
  mapStyle: {
    height: '984px',
    position: 'relative',
    width: '100%',
  },
}

const useStyles = makeStyles((theme) => createStyles({
    container: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        boxShadow: '10px 10px 10px #00000029',
      },
    },
    mapStyle: {
      height: '984px',
      position: 'relative',
      width: '100%',
    },
    routeLocations: {

    },
    infoContainer: {
      marginTop: 16,
      display: 'flex',
      flexDirection: 'row',
    },
    imageContainer: {
      width: 116,
      display: 'flex',
      marginRight: 16,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 58,
      },
    },
    locationImage: {
      borderRadius: '50%',
      width: 96,
      height: 96,
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: 48,
        height: 48,
      },
    },
    title: {
      fontSize: 14,
      fontWeight: 800,
    },
    description: {
      fontSize: 10,
      marginBottom: 8,
    },
    button: {
      backgroundColor: '#82A4EF',
      borderStyle: 'hidden',
      color: 'white',
      padding: '6px 8px',
      textDecoration: 'none',
      display: 'inline-block',
      font: 'normal normal bold 10px/12px Helvetica Neue',
      cursor: 'pointer',
    },
    buttonBorderRadius: {
      borderRadius: 4,
    },
    removeButton: {
      position: 'absolute',
      font: 'normal normal bold 16px/19px Helvetica Neue',
      zIndex: 20,
      bottom: 24,
      right: 64,
    },
    noSartLocation: {
      color: 'red',
      font: 'normal normal bold 10px/12px Helvetica Neue',
      [theme.breakpoints.down('sm')]: {
        font: 'normal normal bold 8px/10px Helvetica Neue',
      },
    },
}))

const MapContainer: FunctionComponent<{google: any}> = ({google}): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const mapRef = useRef();
  const directionsDisplayRef = useRef();
  const activeMarkerLocation = useSelector(getActiveLocationMarker);
  const showingMarkerInfoWindow = useSelector(getShowingMarkerInfoWindow);
  const selectedPlace = useSelector(getSelectedPlace);
  const routeLocations = useSelector(getRouteLocations);
  const startLocation = useSelector(getStartocation);
  const stlc = useSelector(getStartLocationCoordinates);
  const selectedPredefinedRoute = useSelector(getSelectedPredefinedRoute);

  const directionsService = new google.maps.DirectionsService();
  
  const onMarkerClick = (props: IMarkerProps, marker: Marker, e: any) => {
    dispatch(setSelectedPlace(props));
    dispatch(setShowingMarkerInfo(true));
    dispatch(setActiveLocationMarker(marker));
  };

  const onMapClicked = (props: any) => {
    closeInfoViewWindow();
  };

  const addLocation = (locationIsInRouteLocations: boolean) => {
    locationIsInRouteLocations ? dispatch(removeRouteLocation(selectedPlace.id)) : dispatch(addRouteLocation(selectedPlace.id));

    if (selectedPredefinedRoute) {
      dispatch(setSelectedPredefinedRoute(null));
    }

    closeInfoViewWindow();
  }

  const renderAddButton = (e: any) => {
    if (stlc !== null) {
      var locationIsInRouteLocations = routeLocations.includes(selectedPlace.id);
      var buttonText = locationIsInRouteLocations ? 'Verwijderen' : 'Toevoegen';

      if (routeLocations.length === MAX_LOCATIONS_COUNT) {
        const text = (<div className={classes.noSartLocation}>Maximaal aantal locaties bereikt.</div>);
        ReactDOM.render(React.Children.only(text), document.getElementById("addbutton"));
      } else {
        const button = (<button onClick={() => addLocation(locationIsInRouteLocations)} className={`${classes.button} ${classes.buttonBorderRadius}`}>{buttonText}</button>);
        ReactDOM.render(React.Children.only(button), document.getElementById("addbutton"));
      }
    } else {
      const text = (<div className={classes.noSartLocation}>Kies eerst een start / eindlocatie</div>);
      ReactDOM.render(React.Children.only(text), document.getElementById("addbutton"));
    }
  }

  const closeInfoViewWindow = () => {
    if (showingMarkerInfoWindow) {
      dispatch(setShowingMarkerInfo(false));
      dispatch(setActiveLocationMarker(null));
    }
  }

  // Show new route locations when the route locations change
  useEffect(() => {
    calculateAndDisplayRoute(mapRef.current, directionsDisplayRef.current);
  }, [routeLocations]);

  useEffect(() => {
      calculateAndDisplayRoute(mapRef.current, directionsDisplayRef.current);
  }, [stlc]);

   //TODO: useEffect voor infoview
  const renderMarkers = getLocations.map(({id, title, lat, lng, images}, index) => {
      var icon = {
          url: `images/locations/small/${images[0]}`, // url
          scaledSize: new google.maps.Size(40, 40), // scaled size
          origin: new google.maps.Point(0,0), // origin
          anchor: new google.maps.Point(20, 20), // anchor
      };

      return (
        <Marker
          key={id}
          id={id}
          name={title}
          position={{lat: lat, lng: lng}}
          icon={icon}
          onClick={onMarkerClick}
          {...google.map}
        />
      );
    });

  const handleMapReady = (mapProps: any, map: any) => {
    // Save the map instance for later
    mapRef.current = map;

    // Generate directions displayer and connect it to the map
    directionsDisplayRef.current = new google.maps.DirectionsRenderer({
      map: map,
      suppressMarkers: true,
      preserveViewport: true,
      // polylineOptions: polylineOptionsActual
    });

    // Draw any routes if needed
    calculateAndDisplayRoute();

    // I create an OverlayView, and set it to add the "markerLayer" class to the markerLayer DIV
     var markerOverlay = new google.maps.OverlayView();
     markerOverlay.draw = function () {
         this.getPanes().markerLayer.id = 'markerLayer';
     };

     markerOverlay.setMap(map);
  }

  const calculateAndDisplayRoute = (map?: any, directionsDisplay?: any) => {

    // Don't render anything if there is no valid map
    if (!map) {
      console.warn('Map reference is missing!');
      return;
    }

    if (!directionsDisplay) {
      console.warn('Directions reference is missing!');
      return;
    }

    if (routeLocations.length === 0) {
      directionsDisplay.setDirections({routes: []});
      return;
    }

    const origin = stlc !== null ? stlc : null;
    const destination = stlc !== null ? stlc : null;

    // Don't render anything if there is no valid origin or destination.
    if (origin === null || destination === null) {
      console.warn('No valid origin or destination was found');
      return;
    }

    var waypoints;
    if (selectedPredefinedRoute && predefinedRoutes[selectedPredefinedRoute].useRouteLatLng != null) {
      waypoints = predefinedRoutes[selectedPredefinedRoute].useRouteLatLng!.map((item) => {
        return {
          location: { lat: item.lat, lng: item.lng },
          stopover: true,
        };
      });

    } else {
      waypoints = routeLocations.map((item) => {
        return {
          location: { lat: getLocationById(item).lat, lng: getLocationById(item).lng },
          stopover: true,
        };
      });
    }

    console.log(waypoints);

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: "BICYCLING",
      },
      (response: any, status: string): void => {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
          var distance: number = 0;
          for (var i = 0; i < response.routes[0].legs.length; i++) {
            distance += response.routes[0].legs[i].distance.value;
          }

          const totalDistance = Math.round((distance / 1000) * 10) / 10;
          dispatch(setRouteDistance(totalDistance));
          dispatch(setRouteLegs(response.routes[0].legs));
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  const removeRoute = () => {
    dispatch(removedRoute());

    if (selectedPredefinedRoute) {
      dispatch(setSelectedPredefinedRoute(null));
    }
  }

  return (
    <div className={classes.container}>
    <Grid container xs={12} md={7} lg={8} spacing={0}>
        <div className={classes.mapStyle}>
          <button
            onClick={() => removeRoute()}
            className={`${classes.button} ${classes.removeButton}`}
              style={{display: startLocation === null ? 'none' : 'inline'
            }}
          >
            Route verwijderen
          </button>
          <Map
            onReady={handleMapReady}
            google={google}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            zoom={12.0}
            maxZoom={18}
            initialCenter={{
              lat: INITIAL_MAP_CENTER.lat,
              lng: INITIAL_MAP_CENTER.lng
            }}
            style={styles.mapStyle}
            onClick={onMapClicked}
          >
            {renderMarkers}
            {stlc !== null &&
              <Marker
                key={1000}
                id={'startLocation'}
                position={{lat: stlc.lat, lng: stlc.lng}}
                icon={{
                    url: `images/icons/pin.png`, // url
                    scaledSize: new google.maps.Size(46, 46), // scaled size
                    origin: new google.maps.Point(0,0), // origin
                    anchor: new google.maps.Point(23, 46), // anchor
                }}
                {...google.map}
              />
            }

            <InfoWindow
              visible={showingMarkerInfoWindow}
              google={google}
              map={google.map}
              /** @ts-ignore */
              marker={activeMarkerLocation}
              /** @ts-ignore */
              onOpen={e => {
                renderAddButton(e);
              }}>
                <div className={classes.infoContainer}>
                  <div className={classes.imageContainer}>
                    <div className={classes.locationImage}
                      style={{backgroundImage: selectedPlace === null ? '' : `url(images/locations/medium/${getLocationById(selectedPlace.id).images[0]})`
                    }}/>
                  </div>
                  <div>
                    <div className={classes.title}>
                      {selectedPlace === null ? '' : selectedPlace.name}
                    </div>
                    <div className={classes.description}>
                      {selectedPlace === null ? '' : getLocationById(selectedPlace.id).description}
                    </div>
                    <div id="addbutton" />
                  </div>
                </div>
            </InfoWindow>
          </Map>
        </div>
      </Grid>
      <Grid item xs={12} md={5} lg={4} spacing={0}>
        <RouteLocations />
      </Grid>
    </div>
  )
}

const GoogleMap = GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
})(MapContainer)


export default GoogleMap;
