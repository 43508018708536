import { FunctionComponent, useState, useRef } from 'react';
import Slider from 'react-slick';

import { getLocations } from './../../utils/constants';
import { getLocationById } from './../../utils/locationHelper';

import Modal from '@material-ui/core/Modal';
import {createStyles, makeStyles, Container, CircularProgress} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
  },
  title: {
    backgroundColor: '#7AB442',
    display: 'inline-block',
    padding: '16px 32px 16px 32px',
    fontFamiliy: 'Lato',
    fontSize: 50,
    letterSpacing: 0,
    color: '#FFFFFF',
    marginTop: 92,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: 'unset',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
    },
  },
  slide: {
    height: 500,
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  slideContent: {
    padding: 25,
    borderWidth: 0,
  },
  slideImage: {
    width: '453px',
    height: '453px',
    boxShadow: '10px 10px 10px #00000029',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: '200px',
    },
  },
  slideText: {
    font: 'normal normal normal 55px/66px Lato',
    letterSpacing: 0,
    color: '#FFFFFF',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
    wordWrap: 'break-word',
    whitespace: 'no-wrap',
    textShadow: '2px 2px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal normal 22px/33px Lato',
      lineHeight: 'unset',
    },
  },
  modal: {
    width: '80%',
    height: '80%',
    position: 'absolute',
    left: '10%',
    top: '10%',
    outline: 'none',
    borderRadius: 15,
    backgroundColor: 'rgb(231, 240, 220, 0.9)',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      height: '40%',
      left: '10%',
      top: '30%',
    },
  },
  modalInner: {
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    borderRadius: 15,
  },
  modalContent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 16,
  },
  modalTitle: {
    font: 'normal normal bold 65px/79px Helvetica Neue',
    letterSpacing: 0,
    color: '#FFFFFF',
    textShadow: '2px 2px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 'unset',
    },
  },
  modalSubtitle: {
    font: 'italic normal normal 40px/48px Helvetica Neue',
    letterSpacing: 0,
    color: '#FFFFFF',
    textShadow: '2px 2px rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: 'unset',
    },
  },
  modalClose: {
    backgroundImage: `url(images/icons/close_icon.png)`,
    width: 54,
    height: 54,
    cursor: 'pointer',
    position: 'absolute',
    top: 16,
    right: 16,
    imageSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 24,
      height: 24,
    },
  },
  modalCircularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 0,
  },
  sliderButton: {
    background: 'none',
    color: '#7AB442',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    outline: 'inherit',
    height: 80,
    width: 80,
    fontSize: 'large',
  },
  sliderButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const LocationsCarousel: FunctionComponent = () => {
  const classes = useStyles();
  const slickRef = useRef<Slider>(null);

  const [openModal, setOpenModal] = useState(false);
  const [modalLocationId, setModalLocationId] = useState<number>(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    swipeToSlide: true,
    className: "slider variable-width",
    variableWidth: true,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            swipeToSlide: true,
            infinite: true,
            dots: false
          }
        },
    ]
  }

  const handleOpenModal = (locationId: number) => {
    if (!isSwiping) {
      setModalLocationId(locationId);
      setOpenModal(openModal ? false : true);
    }
  }

  const handleCloseModal = () => {
    setOpenModal(openModal ? false : true);
  }

  const renderModal = () => {
      const location = getLocationById(modalLocationId);

      return (<Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
   
        <div className={classes.modal}>
          <div className={classes.modalCircularProgress}>
            <CircularProgress color="inherit"/>
          </div>
          <div className={classes.modalInner} style={{background: `url(images/locations/big/${location.images[0]}) 0% 0% no-repeat padding-box`, backgroundSize: 'cover',}}>
            <div className={classes.modalClose} onClick={handleCloseModal} />
            <div className={classes.modalContent}>
              <div className={classes.modalTitle}>
                {location.title}
              </div>
              <div className={classes.modalSubtitle}>
                {location.description}
              </div>
            </div>
          </div>
        
        </div>
    </Modal>);
  }

  const handlePage = (way) => {
    console.log(way);
    if (way === 'back') {
      if (slickRef != null && slickRef.current != null) {
        slickRef?.current.slickPrev();
      }
      
    } else {
      if (slickRef != null && slickRef.current != null) {
        slickRef?.current.slickNext();
      }
    }
  };

  const renderSlides = getLocations.map(({id, title, images}, index) => {
    return (<div>
        <div className={classes.slideContent}>
          <div className={classes.slideImage} style={{background: `transparent url(images/locations/medium/${images[0]}) 0% 0% no-repeat padding-box`, backgroundSize: 'cover',}} onClick={() => handleOpenModal(id)}>
            <div className={classes.slideText}>
              {title}
            </div>
          </div>
        </div>
      </div>);
  });

      return (<>
          {renderModal()}
          <Container maxWidth="lg" style={{overflow: 'hidden'}}>
            <div className={classes.container}>
              <div className={classes.content}>
                <div className={classes.title}>
                  IN BEELD
                </div>
              </div>
            </div>
          </Container>
          <div className={classes.sliderButtons}>
            <button type="button" className={classes.sliderButton} onClick={() => handlePage('back')}> <ArrowBackIosIcon /> </button>
            <button type="button" className={classes.sliderButton} onClick={() => handlePage('forward')}> <ArrowForwardIosIcon /> </button>
         </div>
          <div className={classes.slide}>
          <Slider ref={slickRef} {...settings} beforeChange={() => setIsSwiping(true)} afterChange={() => setIsSwiping(false)}>
            {renderSlides}
          </Slider>
          </div>
        </>
      );
}

export default LocationsCarousel;
