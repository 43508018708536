import React, { FunctionComponent, useState } from 'react';
import Slider from 'react-slick';

import { getPartners } from './../../utils/constants';
import { getPartnerById } from './../../utils/partnerHelper';
import {createStyles, makeStyles, Container} from "@material-ui/core";

const useStyles = makeStyles((theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    width: '100%',
  },
  title: {
    fontFamiliy: 'Lato',
    fontSize: 50,
    letterSpacing: 0,
    color: '#000000',
    marginTop: 171,
    marginBottom: 75,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: 'unset',
      marginTop: 80
    },
  },
  slide: {
    height: 128,
    [theme.breakpoints.down('md')]: {
      height: 128,
    },
  },
  slideContent: {
    padding: '50px 25px 50px 50px',
    borderWidth: 0,
    [theme.breakpoints.down('md')]: {
      padding: '25px 0px 25px 12px',
    },
  },
  slideImage: {
    width: 432,
    height: 128,
    cursor: 'pointer',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      width: 216,
      height: 64,
    },
  },
}))

const PartnerCarousel: FunctionComponent = () =>{
  // const [modaPartnerId, setModalPartnerId] = useState<number>(0);
  const classes = useStyles();
  const [isSwiping, setIsSwiping] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    swipeToSlide: true,
    className: "slider variable-width",
    variableWidth: true,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            swipeToSlide: true,
            infinite: true,
            dots: false
          }
        },
    ]
  }

  const goToWebsite = (id: number) => {
    if (!isSwiping) {
      const partner = getPartnerById(id);
      window.open(partner.url, '_blank');
    }
  }

  const renderSlides = getPartners.map(({id, image}, index) => {
    return (<div>
        <div className={classes.slideContent}>
          <div className={classes.slideImage} style={{backgroundImage: `url(images/partnerLogos/${image})`}} onClick={() => goToWebsite(id)} />
        </div>
      </div>);
  });

      return (<>
          <Container maxWidth="lg" style={{overflow: 'hidden'}}>
            <div className={classes.container}>
              <div className={classes.content}>
                <div className={classes.title}>
                Partners
                </div>
              </div>
            </div>
          </Container>
          <div className={classes.slide}>
            <Slider {...settings} beforeChange={() => setIsSwiping(true)} afterChange={() => setIsSwiping(false)}>
              {renderSlides}
            </Slider>
          </div>
        </>
      );
}

export default PartnerCarousel;
