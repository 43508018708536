import { MainState } from './types'

// markers
export const getActiveLocationMarker = (state: MainState) => {
  return state.activeLocationMarker;
};

export const getShowingMarkerInfoWindow = (state: MainState) => {
  return state.showingMarkerInfoWindow;
};

export const getSelectedPlace = (state: MainState) => {
  return state.selectedPlace;
}

// locations
export const getRouteLocations = (state: MainState) => {
  return state.routeLocations;
}

export const getStartocation = (state: MainState) => {
  return state.startLocation;
}

export const getStartLocationCoordinates = (state: MainState) => {
  return state.startLocationCoordinates;
}

// route
export const getRouteDistance = (state: MainState) => {
  return state.routeDistance;
}

export const getRouteLegs = (state: MainState) => {
  return state.routeLegs;
}

// predefined route
export const getSelectedPredefinedRoute = (state: MainState) => {
  return state.selectedPredefinedRoute;
}
