import { getLocationById } from './locationHelper';

export const createXmlString = (lines: number[][][]): string => {
  let result = '<?xml version="1.0" encoding="UTF-8"?><gpx version="1.1" creator="Foto Routeplanner Groene Corridor - https://fotoroute.lap.cc/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://www.topografix.com/GPX/1/1" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd" xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1"><trk><trkseg>'
  
  result += lines.reduce((accum, curr) => {
    let segmentTag = '';
    segmentTag += curr.map((point) => `<trkpt lat="${point[0]}" lon="${point[1]}"><ele>${point[2]}</ele></trkpt>`).join('');
    segmentTag += '';

    return accum += segmentTag;
  }, '');
  result += '</trkseg></trk></gpx>';
  return result;
}

export const downloadGpxFile = (routeLegs: any[], routeDistance: number | null) => {
  var lines: number[][][] = [];

  for (var leg = 0; leg < routeLegs.length; leg++) {
    var steps: any[] = routeLegs[leg].steps;
    for (var step = 0; step < steps.length; step++) {
      lines.push([[steps[step].start_location.lat(), steps[step].start_location.lng(), 0]]);
    }
  }

  var distance: number = 0;
  if (routeDistance !== null) {
    distance = routeDistance;
  }
  
  const xml = createXmlString(lines);
  const url = 'data:text/json;charset=utf-8,' + xml;
  const link = document.createElement('a');
  link.download = `${distance}kilometer.gpx`;
  link.href = url;
  document.body.appendChild(link);
  link.click();
};

export const createGoogleMapsUrl = (routeLocations: number[], startLocationCoordinates: any) => {
  var url: string = "https://www.google.com/maps/dir/?api=1&";

  // Add start location and destination
  url += `origin=${startLocationCoordinates.lat},${startLocationCoordinates.lng}&destination=${startLocationCoordinates.lat},${startLocationCoordinates.lng}&waypoints=`;

  // Add wapoints
  for(var i = 0; i < routeLocations.length; i++) {
    url += `${getLocationById(routeLocations[i]).lat},${getLocationById(routeLocations[i]).lng}%7C`;
  }

  // Set travelmode
  url += '&travelmode=bicycling';
 
  return url;
}

export const createGoogleMapsUrlWithLatLng = (locations: { lat: number, lng: number }[] | null, startLocationCoordinates: any) => {
  var url: string = "https://www.google.com/maps/dir/?api=1&";

  // Add start location and destination
  url += `origin=${startLocationCoordinates.lat},${startLocationCoordinates.lng}&destination=${startLocationCoordinates.lat},${startLocationCoordinates.lng}&waypoints=`;

  // Add wapoints
  for(var i = 0; i < locations!.length; i++) {
    url += `${locations![i].lat},${locations![i].lng}%7C`;
  }

  // Set travelmode
  url += '&travelmode=bicycling';
 
  return url;
}