import React, {FunctionComponent} from 'react';
import {createStyles, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => createStyles({
  header: {
    height: 786,
    width: '100%',
    backgroundImage: `url(images/header_background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
  },
  title: {
    backgroundColor: '#7AB442',
    display: 'inline-block',
    padding: '16px 32px 16px 32px',
    font: 'italic normal bold 80px/96px Lato',
    letterSpacing: 0,
    color: '#FFFFFF',
    opacity: 1,
    marginLeft: 84,
    marginTop: 92,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: 'unset',
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 16,
      paddingBottom: 16,
      marginLeft: 16,
    },
  }
}))

const Header:FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div className={classes.title}>
        BELEEF DE GROENE CORRIDOR
      </div>
    </div>
  );
}

export default Header;


