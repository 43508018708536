import React, {FunctionComponent, useState, useEffect, useRef} from 'react';
import {RouteLocationsProps} from './types';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { GOOGLE_API_KEY, MAX_LOCATIONS_COUNT } from './../../utils/constants';
import { getLocationById } from './../../utils/locationHelper';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useDispatch, useSelector } from 'react-redux';
import {
  setRouteLocationsOrder,
  removeRouteLocation,
  setStartLocation,
  setStartLocationCoordinates,
  setSelectedPredefinedRoute,
} from '../../redux/mainActions';

import {
  getRouteLocations,
  getStartocation,
  getSelectedPredefinedRoute,
} from '../../redux/mainSelectors';
import {makeStyles, createStyles} from "@material-ui/core";
import GooglePlacesAutocompleteProps from "react-google-places-autocomplete/build/GooglePlacesAutocomplete.types";

const useStyles = makeStyles((theme) => createStyles({
    container: {
      height: '984px',
      zIndex: 1,
      backgroundColor: '#FFF',
      borderRadius: '0px 20px 0px 0px',
      boxShadow: '10px 10px 10px #00000029', position: 'relative',
      [theme.breakpoints.down('sm')]: {
        borderRadius: '0px 0px 0px 0px',
      },
    },
    startLocationContainer: {
      height: '80px',
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 36,
    },
    startLocationIconContainer: {
      width: '100px',
      height: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '50px',
        height: '35px',
        marginLeft: 8,
      },
    },
    startLocationInput: {
      width: '272px',
      height: '30px',
      marginTop: 15,
      [theme.breakpoints.down('sm')]: {
        width: '240px',
        height: '30px',
        marginTop: 0,
        marginLeft: 16,
      },
    },
    imageContainer: {
      width: '100px',
      height: '70px',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '50px',
        height: '35px',
      },
    },
    locationImage: {
      borderRadius: '50%',
      width: '71px',
      height: '71px',
      border: '2px solid rgba(0, 0, 0, 1)',
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: '35px',
        height: '35px',
        border: '1px solid rgba(0, 0, 0, 1)',
      },
    },
    removeButton: {
      borderRadius: '50%',
      width: '22px',
      height: '22px',
      background: '#F44336 0% 0% no-repeat padding-box',
      position: 'absolute',
      marginLeft: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      [theme.breakpoints.down('sm')]: {
        width: '15px',
        height: '15px',
        fontSize: 10,
        marginLeft: 25,
      },
    },
    infoContainer: {
      width: 300,
      marginTop: -8,
      [theme.breakpoints.down('sm')]: {
        marginTop: -16,
      },
    },
    title: {
      marginTop: 16,
      font: 'normal normal bold 16px/19px Helvetica Neue',
      letterSpacing: 0,
      color: '#000000',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    description: {
      maxHeight: 38,
      overflow: 'hidden',
      font: 'normal normal normal 16px/18px Helvetica Neue',
      letterSpacing: 0,
      color: '#000000',
      [theme.breakpoints.down('sm')]: {
        fontSize: 11,
        maxWidth: 240,
      },
    },
    dragDrop: {
      marginTop: -8,
    },
    nonDragDrop: {
      marginTop: -6,
    },
    destinationContainer: {
      width: 272,
      height: '30px',
      background: '#F7F7F7 0% 0% no-repeat padding-box',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 240,
        marginLeft: 16,
      },
    },
    destinationIconContainer: {
      width: '100px',
      height: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 8,
      marginTop: -18,
      [theme.breakpoints.down('sm')]: {
        width: '50px',
        height: '35px',
        marginLeft: 8,
        marginTop: -4,
      },
    },
    destination: {
      font: 'normal normal normal 12px/14px Helvetica Neue',
      letterSpacing: 0,
      color: '#707070',
      marginLeft: 12,
      width: 248,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pin: {
      width: 46,
      height: 46,
    }
}))

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result: number[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 8;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    height: 70,
    display: 'flex',
    flexDirection: 'row',

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getNonDraggableItemStyle = () => ({
    margin: `0 0 ${grid}px 0`,
    marginLeft: 8,
    height: 70,
    width: 384,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    padding: grid,
    width: 384,
  });

const RouteLocations: FunctionComponent<RouteLocationsProps> = () => {
  const classes = useStyles();
  const routeLocations = useSelector(getRouteLocations);
  const stl = useSelector(getStartocation);
  const [startLocation, setNewStartLocation] = useState<any>(null);
  const selectedPredefinedRoute = useSelector(getSelectedPredefinedRoute);
  const dispatch = useDispatch();

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      routeLocations,
      result.source.index,
      result.destination.index
    );

    dispatch(setRouteLocationsOrder(items));
  }

  const removeLocation = (id: number) => {
    dispatch(removeRouteLocation(id));

    if (selectedPredefinedRoute) {
      dispatch(setSelectedPredefinedRoute(null));
    }
  }

  const getNonDraggableItems = () => {
    var items: any[] = [];
    var count: number = MAX_LOCATIONS_COUNT - routeLocations.length;

    for (var i = 0; i < count; i++) {
      items.push(
        <div key={`nonDraggableRoute-/${i}`} style={getNonDraggableItemStyle()}>
          <div className={classes.imageContainer}>
            <div className={classes.locationImage}>
            </div>
          </div>
        </div>);
    }

    return items;
  }

  const startLocationPlaceholder: string = 'Geef hier je start / eindlocatie in';

  const renderDestinationText = () => {
    if (stl === null) {
      return '';
    } else {
      if (typeof stl === 'object') {
        return stl.label;
      } else {
        return stl;
      }
    }
  }

  // Called when user selects a select option
  const handleChange = (newStartLocation) => {
    console.log('setting new start location', newStartLocation);
    setNewStartLocation(newStartLocation);
  }

  // Location when predefined route is chosen
  useEffect(() => {
    console.log('stl: ', stl);
    if(!stl) {
      return;
    }
    if(stl?.label) {
      console.log('setting input value via stl label', stl?.label);
      return;
    }
    setNewStartLocation({
      label: stl,
      value: stl
    })
  }, [stl]);

  // Location when select is used
  useEffect(() => {
    if (startLocation !== null) {
      geocodeByAddress(startLocation.value.description)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          dispatch(setStartLocation(startLocation));
          dispatch(setStartLocationCoordinates({ lat, lng }));
        });
    }
  }, [dispatch, startLocation])

  return (<div className={classes.container}>
      <div className={classes.startLocationContainer}>
        <div className={classes.startLocationIconContainer}>
          <img src={"./images/icons/pin.png"} className={classes.pin} />
        </div>
        <div className={classes.startLocationInput}>
          <GooglePlacesAutocomplete
          apiKey={GOOGLE_API_KEY}
          autocompletionRequest={{
            componentRestrictions: {
            country: ['nl'],
            }
          }}
          selectProps={{
              value: startLocation,
              onChange: handleChange,
              blurInputOnSelect: true,
              placeholder: startLocation === null ? startLocationPlaceholder : startLocation?.label,
              styles: {
              input: (provided: any) => ({
                ...provided,
                font: 'normal normal normal 12px/14px Helvetica Neue',
                letterSpacing: 0,
                color: '#707070',
                opacity: 1,
              }),
              option: (provided: any) => ({
                ...provided,
                font: 'normal normal normal 12px/14px Helvetica Neue',
                letterSpacing: 0,
                color: '#707070',
                opacity: 1,
              }),
              singleValue: (provided: any) => ({
                ...provided,
                font: 'normal normal normal 12px/14px Helvetica Neue',
                letterSpacing: 0,
                color: '#707070',
                opacity: 1,
              }),
              placeholder: (provided: any) => ({
                ...provided,
                font: 'normal normal normal 12px/14px Helvetica Neue',
                letterSpacing: 0,
                color: '#707070',
                opacity: 1,
              }),
              container: (provided: any) => ({
                ...provided,
                font: 'normal normal normal 12px/14px Helvetica Neue',
                letterSpacing: 0,
                color: '#707070',
                opacity: 1,
                backgroundColor: '#F7F7F7',
                borderRadius: 4,
                height: '30px',
              }),
              control: (provided: any) => ({
                ...provided,
                font: 'normal normal normal 12px/14px Helvetica Neue',
                letterSpacing: 0,
                color: '#707070',
                opacity: 1,
                backgroundColor: '#F7F7F7',
                borderRadius: 4,
                height: '30px',
              }),
            },
            }} />
        </div>
      </div>
      <div className={classes.dragDrop}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {routeLocations.map((item, index) => (
                  <Draggable key={item} draggableId={String(item)} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className={classes.imageContainer}>
                          <div className={classes.locationImage} style={{backgroundImage: `url(images/locations/medium/${getLocationById(item).images[0]})`}} />
                          <div className={classes.removeButton} onClick={() => removeLocation(item)}> X </div>
                        </div>
                        <div className={classes.infoContainer}>
                          <div className={classes.title}>
                            {getLocationById(item).title}
                          </div>
                          <div className={classes.description}>
                            {getLocationById(item).description}
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={classes.nonDragDrop}>
        {getNonDraggableItems()}
      </div>
      <div className={classes.startLocationContainer}>
        <div className={classes.destinationIconContainer}>
          <img src={"./images/icons/pin.png"} className={classes.pin} />
        </div>
        <div className={classes.destinationContainer}>
          <div className={classes.destination}>
            {renderDestinationText()}
          </div>
        </div>
      </div>
  </div>
  );

}

export default RouteLocations;
